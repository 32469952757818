import * as React from "react"
import Layout from "../layouts/default"
import * as style from "../sass/pages/home.module.sass"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PortableText } from '@portabletext/react'





// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <section className={style.mainContent}>
        <h1>WE KNOW <u>COFFEE</u>.</h1>
        <div className={style.lineBreak}></div>
        <h1>WE KNOW <u>BRANDING</u>.</h1>
        <PortableText
          value={data.allSanityHome.edges[0].node._rawBody}
        />
      </section>
      <section className={style.gallery}>
        <GatsbyImage className={style.image} image={data.allSanityHome.edges[0].node.imageLeft.asset.gatsbyImageData} alt="hero" />
        <GatsbyImage className={style.image} image={data.allSanityHome.edges[0].node.imageRight.asset.gatsbyImageData} alt="hero" />
        <GatsbyImage className={style.image} image={data.allSanityHome.edges[0].node.imageFull.asset.gatsbyImageData} alt="hero" />
      </section>
    </Layout>
  )
}

export default IndexPage


export const query = graphql`
{
  allSanityHome {
    edges {
      node {
        _rawBody
        imageLeft {
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
        imageRight {
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
        imageFull {
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
}
`